import axios from 'axios';

type Params = {
    raw: string;
};

export default async function (params: Params): Promise<string> {
    try {
        const {
            data: { result },
        } = await axios.post(`${axios.defaults.baseURL}/wallet/broadcast`, {
            raw: params.raw,
        });

        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
}
