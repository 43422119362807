import React, { FC, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { IoLockOpenOutline, IoStarOutline } from 'react-icons/io5';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { findAddresses } from '../../../services/common';
import { setState } from '../../../redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { Title } from '../components';
import { LoadingButton } from '@mui/lab';

interface Props {
    className?: string;
}

const Mnemonic: FC<Props> = ({ className }) => {
    const [seedPhrase, setSeedPhrase] = useState<string[]>([]);
    const [isUnlocking, setIsUnlocking] = useState(false);
    const dispatch = useDispatch();

    const isButtonDisabled = () => {
        if (seedPhrase.some((e) => e === undefined || e === '')) {
            return true;
        }
        if (seedPhrase.length < 12) {
            return true;
        }
    };

    const importWallet = async () => {
        setIsUnlocking(true);
        const addresses = await findAddresses(seedPhrase);
        dispatch(setState({ seedPhrase, addresses, depositAddress: addresses[0] }));
        setIsUnlocking(false);
    };

    return (
        <div className={className}>
            <Title
                title="How do you want to access your AOK Wallet?"
                description="AOK is trying to make up for the disadvantages of the value of the real money and make exchange of values easier for many people."
            />
            <TextField
                id="mnemonic"
                variant="outlined"
                fullWidth
                className="input"
                label="Enter your wallet mnemonic"
                placeholder="Enter your mnemonic"
                onChange={(e) => setSeedPhrase(e.target.value.split(' '))}
            />
            <LoadingButton
                disabled={isButtonDisabled()}
                loading={isUnlocking}
                loadingPosition="start"
                variant="contained"
                size="large"
                color="primary"
                className="btn"
                fullWidth
                onClick={importWallet}
                startIcon={<IoLockOpenOutline color="white" />}
            >
                <Typography color="white">Unlock</Typography>
            </LoadingButton>
            <Button
                variant="outlined"
                size="large"
                color="primary"
                className="btn"
                disabled={seedPhrase.length === 0}
                startIcon={<IoStarOutline />}
                fullWidth
                component={RouterLink}
                to="/wallet/create"
            >
                Create New Wallet
            </Button>
        </div>
    );
};

export default styled(Mnemonic)`
    .input {
        margin: ${({ theme }) => theme.spacing(4)} auto;
    }
`;
