import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Hidden,
    Typography,
} from '@mui/material';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { resetState } from '../../redux/actions/appActions';
import DesktopNavigation from './desktop/DesktopNavigation';
import MobileNavigation from './mobile/MobileNavigation';

interface NavigationProps {
    className?: string;
}

const Navigation = ({ className }: NavigationProps) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(resetState());
        setOpen(false);
    };

    const logDialog = (o: boolean) => {
        setOpen(o);
    };

    const dialog = (
        <div>
            <Dialog open={open} onClose={() => logDialog(false)}>
                <DialogTitle>
                    <Typography variant="h5" color="primary">
                        Are you sure you want to log out?
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" color="textPrimary">
                        If you have not saved the recovery phrase before, you will not be able to restore this wallet.
                        Please make sure you have a recovery phrase before logging out.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => logDialog(false)} color="primary" size="large" variant="outlined">
                        Disagree
                    </Button>
                    <Button
                        onClick={logout}
                        color="primary"
                        autoFocus
                        size="large"
                        variant="contained"
                        sx={{ marginLeft: 2 }}
                    >
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

    return (
        <div className={className}>
            <Hidden mdDown>
                <DesktopNavigation logDialog={logDialog} />
            </Hidden>
            <Hidden mdUp>
                <MobileNavigation logDialog={logDialog} />
            </Hidden>
            {dialog}
        </div>
    );
};

export default styled(Navigation)``;
