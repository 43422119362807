import React from 'react';
import { Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import styled from 'styled-components';
import { IoCloseOutline } from 'react-icons/io5';
import { Withdraw } from '../../index';

interface Props {
    className?: string;
    open: boolean;
    handleClose: any;
}

const WithdrawDialog = ({ className, handleClose, open }: Props) => {
    return (
        <Dialog open={open} fullScreen onClose={handleClose} className={className}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" size="large">
                    <IoCloseOutline />
                </IconButton>
                <Typography variant="h5">Withdraw</Typography>
            </Toolbar>
            <Withdraw />
        </Dialog>
    );
};

export default styled(WithdrawDialog)``;
