import React from 'react';
import { Box, IconButton, InputAdornment, Link, TextField, useTheme } from '@mui/material';
import styled from 'styled-components';
import { IoCopy } from 'react-icons/io5';
import { QRCodeSVG } from 'qrcode.react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface ShowCodeDrawerProps {
    className?: string;
}

const Deposit = ({ className }: ShowCodeDrawerProps) => {
    const theme = useTheme();
    const depositAddress = useSelector((state: Redux.RootState) => state.app.depositAddress);
    const { enqueueSnackbar } = useSnackbar();

    const onCopy = () => {
        enqueueSnackbar('Address has been successfully copied to clipboard.');
    };
    return (
        <Box
            className={className}
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ margin: theme.spacing(3) }}
        >
            <Link href={`aok://deposit?address=${depositAddress!.address}`} target="_blank">
                <QRCodeSVG size={262} value={`aok://deposit?address=${depositAddress!.address}`} />
            </Link>
            <TextField
                id="code"
                variant="outlined"
                margin="normal"
                fullWidth
                disabled
                defaultValue={depositAddress?.address}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <CopyToClipboard onCopy={onCopy} text={depositAddress!.address}>
                                <IconButton size="small">
                                    <IoCopy />
                                </IconButton>
                            </CopyToClipboard>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

export default styled(Deposit)``;
