import React from 'react';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useBalance } from '../../../../../api';
import { TokenListSkeleton } from './index';
import { NoToken } from '../../../components';

interface Props {
    className?: string;
}

const NftList = ({ className }: Props) => {
    const addresses = useSelector((state: Redux.RootState) => state.app.addresses);
    const { data: balances, isLoading } = useBalance({ addresses: addresses! });

    return (
        <List className={className}>
            {!isLoading ? (
                !balances || balances.tokens.filter((t) => t.tokenName.includes('#')).length === 0 ? (
                    <NoToken message={'You don\x27t have NFTs yet'} />
                ) : (
                    balances.tokens
                        ?.filter((t) => t.tokenName.includes('#'))
                        ?.map((item) => (
                            <ListItem key={item.tokenName}>
                                <ListItemAvatar>
                                    <Avatar>{item.tokenName[0]}</Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={item.tokenName} secondary={item.tokenName.split('#')[0]} />
                            </ListItem>
                        ))
                )
            ) : (
                <TokenListSkeleton rows={5} />
            )}
        </List>
    );
};

export default styled(NftList)``;
