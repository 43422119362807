export const SET_STATE = 'SET_STATE';
export const RESET_STATE = 'RESET_STATE';

export const setState = (payload: Record<string, any>) => ({
    type: SET_STATE,
    payload,
});

export const resetState = () => ({
    type: RESET_STATE,
});
