import { useQuery } from 'react-query';
import axios from 'axios';

type Params = {
    addresses: AOK.Address[];
};

async function fetch(params: Params) {
    try {
        let tokens: AOK.Token[] = [];
        let balance = {
            confirmed: 0,
            locked: 0,
        };

        for (let i = 0; i < params.addresses.length; i++) {
            const {
                data: { result },
            } = await axios.get(`${axios.defaults.baseURL}/wallet/balance/${params.addresses[i].address}`);

            for (let k = 0; k < result.length; k++) {
                if (result[k].currency === 'AOK') {
                    balance = {
                        confirmed: balance.confirmed + result[k].balance,
                        locked: balance.locked + result[k].locked,
                    };
                } else {
                    const tokenIndex = tokens.findIndex((t) => t.tokenName === result[k].currency);

                    if (tokenIndex !== -1) {
                        tokens = [
                            ...tokens.filter((t) => t.tokenName !== result[i].currency),
                            {
                                ...tokens[tokenIndex],
                                confirmed: result[k].balance + tokens[tokenIndex].confirmed,
                                locked: result[k].locked + tokens[tokenIndex].locked,
                            },
                        ];
                    } else {
                        tokens.push({
                            units: result[k].units,
                            tokenName: result[k].currency,
                            confirmed: result[k].balance,
                            locked: result[k].locked,
                        });
                    }
                }
            }
        }

        return {
            balance,
            tokens,
        };
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export default function (params: Params, options?: Record<string, any>) {
    return useQuery<{ balance: { confirmed: number; locked: number }; tokens: AOK.Token[] }, Error>(
        ['balance', params],
        () => fetch(params),
        options,
    );
}
