import React, { FC } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { IoStarOutline } from 'react-icons/io5';
import { Title } from '../components';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
    className?: string;
}

const InitialButton: FC<Props> = ({ className }) => {
    return (
        <div className={className}>
            <Title
                title="How do you want to access your AOK Wallet?"
                description="AOK is trying to make up for the disadvantages of the value of the real money and make exchange of values easier for many people."
            />
            <Button
                variant="contained"
                size="large"
                color="primary"
                className="btn initial"
                component={RouterLink}
                to="/wallet/import"
            >
                Mnemonic
            </Button>
            <Button
                variant="outlined"
                size="large"
                color="primary"
                className="btn"
                startIcon={<IoStarOutline />}
                component={RouterLink}
                to="/wallet/create"
            >
                Create New Wallet
            </Button>
        </div>
    );
};

export default styled(InitialButton)`
    display: flex;
    flex-direction: column;

    .initial {
        margin-top: ${({ theme }) => theme.spacing(4)};
    }
`;
