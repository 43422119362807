import { useQuery } from 'react-query';
import axios from 'axios';

async function fetch() {
    try {
        const {
            data: { result },
        } = await axios.get(`${axios.defaults.baseURL}/wallet/tokens`);

        return Object.keys(result).map((tokenName) => result[tokenName]);
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export default function (options?: Record<string, any>) {
    return useQuery<AOK.Token[], Error>(['tokens'], () => fetch(), options);
}
