import React from 'react';
import { Container, Grid, Hidden, Paper } from '@mui/material';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { History, MobileTabs, MobileWallet, Wallet, Withdraw } from './layout';
import { Navigation } from '../../components';
import mask from '../../assets/back/mask.png';

interface HomeProps {
    className?: string;
}

const Home = ({ className }: HomeProps) => {
    const expandedHistory = useSelector((state: Redux.RootState) => state.app.expandedHistory);

    return (
        <div
            className={className}
            style={{
                background: `url(${mask}) repeat-x`,
            }}
        >
            <Navigation />
            <Container>
                <Hidden smDown>
                    <Wallet />
                    <Grid container spacing={4}>
                        <Grid item sm={12} md={expandedHistory ? 12 : 6}>
                            <History />
                        </Grid>
                        <Grid item sm={12} md={6} hidden={expandedHistory}>
                            <Paper className="withdraw-box">
                                <Withdraw />
                            </Paper>
                        </Grid>
                    </Grid>
                </Hidden>
            </Container>
            <Hidden smUp>
                <MobileWallet />
                <MobileTabs />
            </Hidden>
        </div>
    );
};

export default styled(Home)`
    .withdraw-box {
        height: 100%;
    }
`;
