import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import styled from 'styled-components';
import { IoLockClosedOutline } from 'react-icons/io5';
import NumberFormat from 'react-number-format';

interface WalletItemProps {
    className?: string;
    currency: string;
    confirmed: number;
    locked: number;
    active?: boolean;
    onClick?: () => void;
}

const WalletListItem = ({ className, currency, confirmed, locked, onClick }: WalletItemProps) => {
    return (
        <Paper elevation={1} className={className} onClick={onClick} style={{ borderRadius: '8px' }}>
            <Grid container justifyContent="space-between" className="header" alignItems="center">
                <Grid item container sm={8} alignItems="center" columnSpacing={1} wrap="nowrap">
                    <Grid item>
                        <IoLockClosedOutline className="item-icon" />
                    </Grid>
                    <Grid item>
                        <Typography color="primary" display="inline">
                            <NumberFormat
                                displayType="text"
                                decimalScale={4}
                                thousandSeparator
                                fixedDecimalScale
                                value={locked / 10 ** 8}
                                suffix={` ${currency}`}
                            />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography color="primary" variant="h6">
                        <NumberFormat
                            displayType="text"
                            decimalScale={4}
                            thousandSeparator
                            fixedDecimalScale
                            value={confirmed / 10 ** 8}
                            suffix={` ${currency}`}
                        />
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default styled(WalletListItem)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing(2)};
    opacity: ${(prop) => (prop.active ? 1 : 0.2)};
    width: 225px;
    height: 160px;

    .item-icon {
        color: ${({ theme }) => theme.palette.primary.main};
    }

    .header {
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
`;
