import React from 'react';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import styled from 'styled-components';
import NftListSkeleton from './NftListSkeleton';
import { useSelector } from 'react-redux';
import { useBalance } from '../../../../api';
import { NoToken } from '../index';

interface Props {
    className?: string;
    open: boolean;
    handleClose: any;
}

const NftDialog = ({ className, handleClose, open }: Props) => {
    const addresses = useSelector((state: Redux.RootState) => state.app.addresses);
    const { data: balances, isLoading } = useBalance({ addresses: addresses! });

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'lg'} className={className}>
            <DialogTitle>
                <Typography variant="h5" color="primary">
                    NFT Tokens
                </Typography>
            </DialogTitle>
            <DialogContent>
                <List sx={{ maxHeight: 355 }}>
                    {!isLoading ? (
                        !balances || balances.tokens.length === 0 ? (
                            <NoToken message={'You don\x27t have NFTs yet'} />
                        ) : (
                            balances.tokens
                                ?.filter((t) => t.tokenName.includes('#'))
                                ?.map((item) => (
                                    <ListItem key={item.tokenName}>
                                        <ListItemAvatar>
                                            <Avatar>{item.tokenName[0]}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item.tokenName}
                                            secondary={item.tokenName.split('#')[0]}
                                        />
                                    </ListItem>
                                ))
                        )
                    ) : (
                        <NftListSkeleton rows={5} />
                    )}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" size="large" variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default styled(NftDialog)``;
