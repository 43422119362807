import React from 'react';
import { Box, Container, Grid, Hidden, List, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import styled from 'styled-components';
import { IoBarChartOutline, IoPeopleOutline } from 'react-icons/io5';

import aokLogo from '../../assets/aok/logo.svg';
import mask from '../../assets/back/mask.png';
import { InitialButton, Mnemonic, NewWallet } from './layout';
import { useParams } from 'react-router';

interface MainProps {
    className?: string;
}

const Main = ({ className }: MainProps) => {
    const { state } = useParams<{ state: string }>();

    const title = (
        <Box className="title-box">
            <img src={aokLogo} alt="Logo" className="logo-icon" />
            <Typography className="title-txt" variant="h3" color="white">
                AOK Wallet
            </Typography>
            <Typography color="white">
                Try a new cryptocurrency. AOK is faster than Bitcoin and thinks about the environment by preventing
                excessive use of resources.
            </Typography>
        </Box>
    );

    const listItem = [
        { icon: <IoPeopleOutline color="white" />, title: 'About us', link: 'https://aok.network/' },
        { icon: <IoBarChartOutline color="white" />, title: 'AOK Explorer', link: 'https://aokscan.com/' },
    ];
    const list = (
        <List className="list">
            {listItem.map((item) => (
                <ListItemButton key={item.title} component="a" href={item.link} target="_blank">
                    <ListItemIcon style={{ fontSize: '32px' }}>{item.icon}</ListItemIcon>
                    <Typography color="white">{item.title}</Typography>
                </ListItemButton>
            ))}
        </List>
    );

    let content;
    switch (state) {
        case 'import':
            content = <Mnemonic />;
            break;
        case 'create':
            content = <NewWallet />;
            break;
        default:
            content = <InitialButton />;
    }

    return (
        <div className={className}>
            <Container>
                <Grid container className="grid" justifyContent="center">
                    <Hidden smDown>
                        <Grid item sm={5} container direction="column" justifyContent="center" alignItems="flex-start">
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                                className="main-first-grid"
                            >
                                {title}
                                {list}
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item sm={5} container direction="column" justifyContent="center" alignItems="stretch">
                        {content}
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default styled(Main)`
    @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
        background: url(${mask}) repeat-y left;
        background-size: 47%;
    }

    .grid {
        height: 100vh;
    }

    .logo-icon {
        height: 72px;
        margin-bottom: ${({ theme }) => theme.spacing(4)};
    }

    .title-txt {
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }

    .btn {
        margin-bottom: ${({ theme }) => theme.spacing(2)};
    }

    .main-first-grid {
        margin-right: ${({ theme }) => theme.spacing(5)};
        height: 50%;
    }
`;
