import axios from 'axios';

export default async function (): Promise<{ blocks: number; mediantime: number }> {
    try {
        const {
            data: { result },
        } = await axios.get(`${axios.defaults.baseURL}/wallet/info`);

        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
}
