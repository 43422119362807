import React from 'react';
import { Avatar, Box, Link, ListItem, ListItemAvatar, ListItemText, Typography, useMediaQuery } from '@mui/material';
import { IoEnterOutline, IoExitOutline } from 'react-icons/io5';
import { formatDistance } from 'date-fns';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import aok from '../../../../assets/aok/aok-logo-black.svg';

interface HistoryListItemProps extends AOK.Transaction {
    className?: string;
}

const HistoryListItem = ({ className, type, amount, currency, to, from, time, hash }: HistoryListItemProps) => {
    // @ts-ignore
    const desktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    return (
        <ListItem
            className={className}
            component={Link}
            target="_blank"
            href={`https://aokscan.com/transaction/${hash}`}
        >
            <ListItemAvatar>
                <Avatar alt="exit">{type === 'sent' ? <IoExitOutline /> : <IoEnterOutline />}</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Box display="flex" justifyContent="space-between">
                        <Typography color="textPrimary">
                            {type === 'sent'
                                ? desktop
                                    ? 'Sending of payment'
                                    : 'Sent'
                                : desktop
                                ? 'Receiving payment'
                                : 'Received'}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            <Typography className="action-value" color="textPrimary">
                                <NumberFormat
                                    displayType="text"
                                    decimalScale={4}
                                    fixedDecimalScale
                                    thousandSeparator
                                    value={amount / 10 ** 8}
                                    prefix={amount == 0 ? '' : type === 'sent' ? '-' : '+'}
                                />
                            </Typography>
                            {currency === 'AOK' ? (
                                <div className="logo-box">
                                    <img src={aok} alt="coin" width={20} height={20} />
                                </div>
                            ) : (
                                <Typography variant="body1" color="textPrimary">
                                    {currency}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                }
                secondary={
                    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
                        <Typography variant="body2" overflow="hidden" textOverflow="ellipsis" maxWidth={200}>
                            {type === 'sent' ? to : from}
                        </Typography>
                        <Typography className="action-value history-time" variant={desktop ? 'body1' : 'caption'}>
                            {formatDistance(time * 1000, new Date(), { addSuffix: true })}
                        </Typography>
                    </Box>
                }
            />
        </ListItem>
    );
};

export default styled(HistoryListItem)`
    width: 100%;

    .action-value {
        padding-right: ${({ theme }) => theme.spacing(0)};
    }
    .logo-box {
        height: 20px;
    }
    .history-time {
        min-width: 110px;
        text-align: end;
    }
`;
