import React from 'react';
import { List } from '@mui/material';
import styled from 'styled-components';
import 'date-fns';
import { useSelector } from 'react-redux';
import { HistoryListItem, HistorySkeleton, NoHistory } from '../../components';
import { useTransactions } from '../../../../api';

interface Props {
    className?: string;
    count?: number;
}

const HistoryList: React.FC<Props> = ({ className, count }) => {
    const addresses = useSelector((state: Redux.RootState) => state.app.addresses);
    const { data: transactions, isLoading } = useTransactions({ addresses: addresses! });

    const historyList = (
        <>
            {transactions?.slice(0, count).map((item, index) => (
                <HistoryListItem {...item} key={index} />
            ))}
        </>
    );

    return (
        <List className={className}>
            {!isLoading ? (
                <>{!transactions || transactions.length === 0 ? <NoHistory /> : historyList}</>
            ) : (
                <HistorySkeleton rows={5} />
            )}
        </List>
    );
};

export default styled(HistoryList)``;
