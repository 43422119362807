import React, { useState } from 'react';
import { Paper, Tab, Tabs } from '@mui/material';
import styled from 'styled-components';
import { HistoryList } from '../../components';
import { TabPanel } from '../../../../components';
import { MobileButtons, NftList, TokensList, WithdrawDialog } from './components';
import DepositDialog from './components/DepositDialog';

interface MobileHistoryWithdrawProps {
    className?: string;
}

const MobileTabs = ({ className }: MobileHistoryWithdrawProps) => {
    const [tabItems] = useState([
        {
            value: 0,
            title: 'History',
        },
        {
            value: 1,
            title: 'Tokens',
        },
        {
            value: 2,
            title: 'NFT',
        },
    ]);
    const [activeTab, setActiveTab] = useState(0);
    const [withdrawDialogOpen, setWithdrawDialogOpen] = useState(false);
    const [depositDialogOpen, setDepositDialogOpen] = useState(false);

    const handleTabChange = (event: any, newValue: any) => {
        setActiveTab(newValue);
    };

    const tabs = tabItems.map((item) => {
        return <Tab key={item.title} label={item.title} value={item.value} className="tab" />;
    });

    return (
        <div className={className}>
            <MobileButtons
                handleWithdrawOpen={() => setWithdrawDialogOpen(true)}
                handleDepositOpen={() => setDepositDialogOpen(true)}
                className="dw-btn"
            />
            <Paper style={{ borderRadius: '8px' }} elevation={0}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                    variant="fullWidth"
                    className="tabs"
                >
                    {tabs}
                </Tabs>
                <TabPanel value={activeTab} index={0} className="history-tab-panel">
                    <HistoryList />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <TokensList />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <NftList />
                </TabPanel>
                <WithdrawDialog open={withdrawDialogOpen} handleClose={() => setWithdrawDialogOpen(false)} />
                <DepositDialog open={depositDialogOpen} handleClose={() => setDepositDialogOpen(false)} />
            </Paper>
        </div>
    );
};

export default styled(MobileTabs)`
    .history-tab-panel {
        min-height: 60vh;
    }

    .dw-btn {
        margin: ${({ theme }) => theme.spacing(2, 0)};
    }
`;
