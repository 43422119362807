import { RESET_STATE, SET_STATE } from '../actions/appActions';

const initialState: Redux.ReducerState = {
    seedPhrase: undefined,
    addresses: undefined,
    depositAddress: undefined,
    expandedHistory: false,
    balance: undefined,
    transactions: [],
    currency: undefined,
};

const appReducer = (state: Redux.ReducerState = initialState, action: Redux.Action) => {
    switch (action.type) {
        case SET_STATE:
            return {
                ...state,
                ...action.payload,
            };
        case RESET_STATE:
            return initialState;
        default: {
            return state;
        }
    }
};

export default appReducer;
