import React from 'react';
import { ThemeProvider } from 'styled-components';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { Redirect, Route, Switch } from 'react-router';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useSelector } from 'react-redux';
import { GlobalStyle, theme } from '.';
import { Home, Main } from '../pages';
import { SnackbarProvider } from 'notistack';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 10000,
        },
    },
});

const App = () => {
    const seedPhrase = useSelector((state: Redux.RootState) => state.app.seedPhrase);

    return (
        <SnackbarProvider maxSnack={3}>
            <QueryClientProvider client={queryClient}>
                <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                        <GlobalStyle />
                        <CssBaseline />
                        <Switch>
                            {seedPhrase && (
                                <>
                                    <Route path="/" exact component={Home} />
                                    <Redirect to="/" />
                                </>
                            )}
                            {!seedPhrase && (
                                <>
                                    <Route path="/wallet/:state?" exact component={Main} />
                                    <Redirect to="/wallet" />
                                </>
                            )}
                        </Switch>
                    </ThemeProvider>
                </MuiThemeProvider>
            </QueryClientProvider>
        </SnackbarProvider>
    );
};

export default App;
