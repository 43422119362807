import axios from 'axios';

type Params = {
    address: string;
    amount?: number;
    token?: string;
};

export default async function (params: Params): Promise<AOK.UTXO[]> {
    try {
        const {
            data: { result },
        } = await axios.get(`${axios.defaults.baseURL}/wallet/unspent/${params.address}`, {
            params,
        });

        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
}
