import React from 'react';
import { Popover, useTheme } from '@mui/material';
import styled from 'styled-components';
import Deposit from './Deposit';

interface ShowCodeDrawerProps {
    className?: string;
    onClose: () => void;
    anchorEl: null | HTMLElement;
}

const DepositPopover = ({ className, onClose, anchorEl }: ShowCodeDrawerProps) => {
    const theme = useTheme();

    return (
        <Popover
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
            elevation={2}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            style={{ marginTop: theme.spacing(2) }}
            className={className}
        >
            <Deposit />
        </Popover>
    );
};

export default styled(DepositPopover)`
    .form {
        width: 100%;
    }
`;
