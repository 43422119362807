import axios from 'axios';

type Params = {
    addresses: AOK.Address[];
};

export default async function (params: Params): Promise<string[]> {
    try {
        const {
            data: { result },
        } = await axios.post(`${axios.defaults.baseURL}/wallet/check`, {
            addresses: params.addresses.map((a) => a.address),
        });

        return result;
    } catch (e) {
        console.error(e);
        throw e;
    }
}
