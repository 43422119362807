import React from 'react';
import { Fab, Typography } from '@mui/material';
import styled from 'styled-components';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';

interface Props {
    className?: string;
    handleWithdrawOpen?: any;
    handleDepositOpen?: any;
}

const MobileButtons = ({ className, handleWithdrawOpen, handleDepositOpen }: Props) => {
    return (
        <div className={className}>
            <div className="btn-box">
                <Fab color="whiteBtn" onClick={handleDepositOpen} size="medium" className="btn">
                    <KeyboardArrowDownRoundedIcon fontSize="large" color="primary" />
                </Fab>
                <Typography variant="caption" color="white" fontWeight="bold">
                    Deposit
                </Typography>
            </div>
            <div className="btn-box">
                <Fab color="whiteBtn" onClick={handleWithdrawOpen} size="medium" className="btn">
                    <KeyboardArrowUpRoundedIcon fontSize="large" color="primary" />
                </Fab>
                <Typography variant="caption" color="white" fontWeight="bold">
                    Withdraw
                </Typography>
            </div>
        </div>
    );
};

export default styled(MobileButtons)`
    display: flex;
    align-items: center;
    justify-content: space-around;

    .btn-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn {
            margin-bottom: ${({ theme }) => theme.spacing(1)};
        }
    }
`;
