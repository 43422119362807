import React, { useEffect, useState } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import styled from 'styled-components';
import { IoQrCodeOutline, IoImage } from 'react-icons/io5';

import { NftDialog, NoToken, DepositPopover, WalletListItem, WalletListItemSkeleton } from '../../components';
import '@brainhubeu/react-carousel/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useBalance } from '../../../../api';
import { setState } from '../../../../redux/actions/appActions';

interface WalletProps {
    className?: string;
}

const Wallet = ({ className }: WalletProps) => {
    const addresses = useSelector((state: Redux.RootState) => state.app.addresses);
    const activeWallet = useSelector((state: Redux.RootState) => state.app.currency);
    const dispatch = useDispatch();
    const { data: balances, isLoading } = useBalance({ addresses: addresses! });

    const [depositPopover, setDepositPopover] = useState<null | HTMLElement>(null);
    const [nftDialogOpen, setNftDialogOpen] = useState(false);
    const handleDepositClick = (event: React.MouseEvent<HTMLElement>) => {
        setDepositPopover(event.currentTarget);
    };

    const handleDepositClose = () => {
        setDepositPopover(null);
    };

    const renderWalletItemSkeletons = () => {
        return new Array(4).fill(null).map((tx, index) => (
            <SwiperSlide key={index}>
                <WalletListItemSkeleton />
            </SwiperSlide>
        ));
    };

    useEffect(() => {
        dispatch(setState({ currency: 'AOK' }));
    }, []);

    const aokToken = balances && (
        <SwiperSlide>
            <WalletListItem
                {...balances.balance}
                currency="AOK"
                active={'AOK' === activeWallet}
                onClick={() => {
                    dispatch(setState({ currency: 'AOK' }));
                }}
            />
        </SwiperSlide>
    );

    const tokens =
        balances &&
        balances.tokens
            ?.filter((t) => !t.tokenName.includes('#'))
            ?.map((item) => (
                <SwiperSlide key={item.tokenName}>
                    <WalletListItem
                        currency={item.tokenName}
                        confirmed={item.confirmed}
                        locked={item.locked!}
                        active={item.tokenName === activeWallet}
                        onClick={() => {
                            dispatch(setState({ currency: item.tokenName }));
                        }}
                    />
                </SwiperSlide>
            ));

    return (
        <div className={className}>
            <Box display="flex" justifyContent="space-between" className="item-header">
                <Typography variant="h5" color="white">
                    Wallet
                </Typography>
                <div>
                    <Button
                        variant="contained"
                        className="btn-wallet"
                        color="whiteBtn"
                        endIcon={<IoImage className="item-icon" />}
                        onClick={() => setNftDialogOpen(true)}
                    >
                        <Typography color="primary">NFT</Typography>
                    </Button>
                    <Button
                        variant="contained"
                        className="btn-wallet"
                        color="whiteBtn"
                        endIcon={<IoQrCodeOutline className="item-icon" />}
                        onClick={handleDepositClick}
                    >
                        <Typography color="primary">Deposit</Typography>
                    </Button>
                    <DepositPopover onClose={handleDepositClose} anchorEl={depositPopover} />
                    <NftDialog open={nftDialogOpen} handleClose={() => setNftDialogOpen(false)} />
                </div>
            </Box>
            <div>
                {!isLoading ? (
                    <Swiper spaceBetween={20} slidesPerView="auto" allowTouchMove={false}>
                        {aokToken}
                        {!balances || balances.tokens.length === 0 ? (
                            <SwiperSlide>
                                <Paper elevation={1} style={{ borderRadius: '8px' }} className="paper-no-token">
                                    <NoToken />
                                </Paper>
                            </SwiperSlide>
                        ) : (
                            tokens
                        )}
                    </Swiper>
                ) : (
                    <Swiper spaceBetween={20} slidesPerView="auto">
                        {renderWalletItemSkeletons()}
                    </Swiper>
                )}
            </div>
        </div>
    );
};

export default styled(Wallet)`
    padding: ${({ theme }) => theme.spacing(4)};

    .btn-wallet {
        margin-left: ${({ theme }) => theme.spacing(2)};
    }

    .item-header {
        padding: 0 ${({ theme }) => theme.spacing(4)};
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }

    .item-icon {
        color: ${({ theme }) => theme.palette.primary.main};
    }

    .swiper-slide {
        width: fit-content;
    }

    .swiper {
        &:before {
            content: ' ';
            display: block;
            width: 64px;
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;
            z-index: 2;
            background: linear-gradient(-90deg, rgb(0, 101, 94), transparent);
        }
    }

    .paper-no-token {
        opacity: 0.9;
        width: 225px;
        height: 160px;
        padding: ${({ theme }) => theme.spacing(2)};
    }
`;
