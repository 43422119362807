import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import aok from '../../../../assets/aok/aok-logo.svg';

interface Props {
    className?: string;
    message?: string;
}

const NoToken = ({ className, message }: Props) => {
    return (
        <Box className={className} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <img src={aok} alt="aok" className="no-token-img" />
            <Typography color="primary">{message ? message : 'You don\x27t have tokens yet'}</Typography>
        </Box>
    );
};

export default styled(NoToken)`
    .no-token-img {
        opacity: 35%;
        height: 75px;
        margin: ${({ theme }) => theme.spacing(2)};
    }
`;
