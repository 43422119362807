import React, { FC } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

interface Props {
    className?: string;
    title: string;
    description: string;
}

const Title: FC<Props> = ({ title, description }) => {
    return (
        <div>
            <Typography className="title-txt" variant="h4" color="primary">
                {title}
            </Typography>
            <Typography color="primary">{description}</Typography>
        </div>
    );
};

export default styled(Title)``;
