import React from 'react';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useBalance } from '../../../../../api';
import { NoToken } from '../../../components';
import { TokenListSkeleton } from './index';

interface Props {
    className?: string;
}

const TokensList = ({ className }: Props) => {
    const addresses = useSelector((state: Redux.RootState) => state.app.addresses);
    const { data: balances, isLoading } = useBalance({ addresses: addresses! });

    return (
        <List className={className}>
            {!isLoading ? (
                !balances || balances.tokens.filter((t) => !t.tokenName.includes('#')).length === 0 ? (
                    <NoToken />
                ) : (
                    balances.tokens
                        ?.filter((t) => !t.tokenName.includes('#'))
                        ?.map((item) => (
                            <ListItem key={item.tokenName}>
                                <ListItemAvatar>
                                    <Avatar>{item.tokenName[0]}</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.tokenName}
                                    secondary={
                                        <NumberFormat
                                            displayType="text"
                                            decimalScale={4}
                                            fixedDecimalScale
                                            thousandSeparator
                                            value={item.confirmed / 10 ** 8}
                                            suffix={` ${item.tokenName.split('#')[0]}`}
                                        />
                                    }
                                />
                            </ListItem>
                        ))
                )
            ) : (
                <TokenListSkeleton rows={5} />
            )}
        </List>
    );
};

export default styled(TokensList)``;
