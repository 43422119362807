import { createTheme } from '@mui/material/styles';
import { createGlobalStyle, DefaultTheme } from 'styled-components';
import { PaletteColorOptions, Theme } from '@mui/material';

interface CompinedTheme extends DefaultTheme, Theme {}

interface GlobalStyleProps {
    theme: CompinedTheme;
}

declare module '@mui/material/styles' {
    interface CustomPalette {
        whiteBtn: PaletteColorOptions;
    }
    interface Palette extends CustomPalette {}
    interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        whiteBtn: true;
    }
}

declare module '@mui/material/Fab' {
    interface FabPropsColorOverrides {
        whiteBtn: true;
    }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

const theme = createTheme({
    spacing: [4, 8, 16, 32, 48, 96],
    typography: {
        fontFamily: 'Titillium Web, Lora, sans-serif',
        h5: {
            fontFamily: '"Lora"',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '40px',
            letterSpacing: '0.002em',
        },
        h4: {
            fontFamily: '"Lora"',
            fontWeight: 500,
            fontSize: '34px',
        },
        h3: {
            fontFamily: '"Lora"',
            fontWeight: 500,
            lineHeight: '72px',
        },
        button: {
            fontWeight: 400,
            fontSize: '16px',
            textTransform: 'none',
        },
        body1: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            lineHeight: '24px',
            letterSpacing: '0.005em',
        },
    },
    palette: {
        whiteBtn: createColor('#fff'),
        primary: {
            main: '#00665E',
            dark: '#003B34',
            light: '#42948B',
            contrastText: '#BDD7D5',
        },
        text: {
            // primary: '#fff',
        },
    },
});

theme.components = {
    ...theme.components,
    MuiButton: {
        variants: [
            {
                props: { variant: 'contained' },
                style: {
                    boxShadow: 'none',
                    borderRadius: '2px',
                    disableElevation: true,
                },
            },
            {
                props: { variant: 'outlined' },
                style: {
                    boxShadow: 'none',
                    borderRadius: '2px',
                },
            },
        ],
    },
    MuiDialog: {
        styleOverrides: {
            paper: {
                borderRadius: 0,
                padding: '16px',
            },
        },
    },
    MuiOutlinedInput: {
        variants: [
            // variants will help you define the props given by Mui and the styles you want to apply for it
            {
                props: { disabled: true },
                style: {
                    backgroundColor: '#f5f5f5',
                },
            },
        ],
    },
    MuiBadge: {
        styleOverrides: {
            root: {
                colorPrimary: {
                    padding: '0',
                    color: '#00665E',
                    backgroundColor: '#BDD7D5',
                },
                colorSecondary: {
                    padding: '0',
                    color: '#C78B00',
                    backgroundColor: '#FFEEBD',
                },
                colorError: {
                    padding: '0',
                    color: '#F44336',
                    backgroundColor: '#FCCECB',
                },
            },
        },
    },
    MuiAvatar: {
        styleOverrides: {
            colorDefault: {
                color: '#9E9E9E',
                backgroundColor: '#F5F5F5',
            },
        },
    },
};

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  .tabs {
    border-bottom: 1px solid ${({ theme: t }) => t.palette.grey[300]};
  }
`;

export { GlobalStyle, theme };
