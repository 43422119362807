import React from 'react';
import { Grid, Paper, Typography, Skeleton } from '@mui/material';
import styled from 'styled-components';

interface Props {
    className?: string;
}

const WalletListItemSkeleton = ({ className }: Props) => {
    return (
        <Paper elevation={1} className={className} style={{ borderRadius: '8px' }}>
            <Grid container justifyContent="space-between" className="header" alignItems="center">
                <Grid item container sm={8} alignItems="flex-end" spacing={1} direction="row">
                    <Grid item>
                        <Typography>
                            <Skeleton width={100} animation="wave" />
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Typography variant="h6">
                        <Skeleton width={100} animation="wave" />
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default styled(WalletListItemSkeleton)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing(2)};
    margin-right: ${({ theme }) => theme.spacing(2)};
    width: 225px;
    height: 160px;

    .item-icon {
        color: ${({ theme }) => theme.palette.primary.main};
    }

    .header {
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }
`;
