import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Button, Typography } from '@mui/material';
import { IoLockOpenOutline } from 'react-icons/io5';
import { Title } from '../components';
import { generateSeedPhrase } from '../../../services/address';
import { findAddresses } from '../../../services/common';
import { setState } from '../../../redux/actions/appActions';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

interface Props {
    className?: string;
}

const NewWallet: FC<Props> = ({ className }) => {
    const [seedPhrase, setSeedPhrase] = useState<string[]>([]);
    const [isUnlocking, setIsUnlocking] = useState(false);
    const dispatch = useDispatch();

    const createNewWallet = async () => {
        setIsUnlocking(true);
        const addresses = await findAddresses(seedPhrase);
        dispatch(setState({ seedPhrase, addresses, depositAddress: addresses[0] }));
        setIsUnlocking(false);
    };

    useEffect(() => {
        generateSeedPhrase().then((r) => {
            setSeedPhrase(r);
        });
    }, []);

    return (
        <div className={className}>
            <Title
                title="Create new Wallet"
                description="The new wallet is created together with a mnemonic phrase. Write the mnemonic phrase down, you will need it to access your wallet. Don’t copy it to your clipboard or save it anywhere online."
            />
            <Box className="phrase-box">
                <Typography color="error" align="center">
                    {seedPhrase.join(' ')}
                </Typography>
            </Box>
            <LoadingButton
                variant="contained"
                size="large"
                loading={isUnlocking}
                loadingPosition="start"
                className="btn red-btn"
                fullWidth
                onClick={createNewWallet}
                startIcon={<IoLockOpenOutline color="white" />}
            >
                <Typography variant="button" color="white">
                    I memorized the code
                </Typography>
            </LoadingButton>
            <Button size="large" color="primary" className="btn" fullWidth component={RouterLink} to="/wallet/import">
                I want to log in or register another wallet
            </Button>
        </div>
    );
};

export default styled(NewWallet)`
    .phrase-box {
        width: 100%;
        outline: 1px ${({ theme }) => theme.palette.error.main} solid;
        padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(4)};
        margin: ${({ theme }) => theme.spacing(4)} auto;
    }

    .red-btn {
        background-color: ${({ theme }) => theme.palette.error.main};
    }
`;
