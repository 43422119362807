import React, { FC, useState } from 'react';
import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    SvgIcon,
    Typography,
} from '@mui/material';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import styled from 'styled-components';
import { IoLockClosedOutline } from 'react-icons/io5';
import '@brainhubeu/react-carousel/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useBalance } from '../../../api';
import NumberFormat from 'react-number-format';
import { FiChevronDown } from 'react-icons/fi';
import NftListSkeleton from './wallet/NftListSkeleton';
import { setState } from '../../../redux/actions/appActions';

interface Props {
    className?: string;
}

const CurrencySelect: FC<Props> = ({ className }) => {
    const [open, setOpen] = useState(false);
    const addresses = useSelector((state: Redux.RootState) => state.app.addresses);
    const currency = useSelector((state: Redux.RootState) => state.app.currency);
    const dispatch = useDispatch();
    const { data: balances, isLoading } = useBalance({ addresses: addresses! });

    const selectedBalance =
        balances && (currency === 'AOK' ? balances.balance : balances.tokens.find((t) => t.tokenName === currency));

    const setCurrency = (newCurrency: string) => {
        dispatch(setState({ currency: newCurrency }));
        setOpen(false);
    };

    return (
        <div className={className}>
            <ButtonBase className="btn" onClick={() => setOpen(true)}>
                <Box textAlign="left">
                    <Typography fontWeight={600}>{currency}</Typography>
                    <Typography variant="h6">
                        <NumberFormat
                            value={selectedBalance ? selectedBalance.confirmed / 10 ** 8 : 0}
                            displayType="text"
                            thousandSeparator
                            suffix={` ${currency}`}
                            fixedDecimalScale
                            decimalScale={4}
                        />
                    </Typography>
                    <Grid container columnSpacing={1} wrap="nowrap" alignItems="center">
                        <Grid item>
                            <Typography color="textSecondary">
                                <IoLockClosedOutline />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography color="textSecondary">
                                <NumberFormat
                                    value={selectedBalance ? selectedBalance.locked / 10 ** 8 : 0}
                                    displayType="text"
                                    thousandSeparator
                                    fixedDecimalScale
                                    decimalScale={4}
                                    suffix={` ${currency}`}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Typography color="textSecondary">
                    <SvgIcon>
                        <FiChevronDown />
                    </SvgIcon>
                </Typography>
            </ButtonBase>
            <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'lg'}>
                <DialogTitle>
                    <Typography variant="h5" color="primary">
                        Choose Currency
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <List sx={{ maxHeight: 355 }}>
                        {!isLoading && balances && (
                            <ListItem onClick={() => setCurrency('AOK')} button>
                                <ListItemAvatar>
                                    <Avatar>A</Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary="AOK"
                                    secondary={
                                        <NumberFormat
                                            value={balances.balance.confirmed / 10 ** 8}
                                            displayType="text"
                                            thousandSeparator
                                            suffix=" AOK"
                                            fixedDecimalScale
                                            decimalScale={4}
                                        />
                                    }
                                />
                            </ListItem>
                        )}
                        {!isLoading && balances ? (
                            balances.tokens.map((item) => (
                                <ListItem key={item.tokenName} onClick={() => setCurrency(item.tokenName)} button>
                                    <ListItemAvatar>
                                        <Avatar>{item.tokenName[0]}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={item.tokenName}
                                        secondary={
                                            <NumberFormat
                                                value={item.confirmed / 10 ** 8}
                                                displayType="text"
                                                thousandSeparator
                                                suffix={` ${item.tokenName}`}
                                                fixedDecimalScale
                                                decimalScale={4}
                                            />
                                        }
                                    />
                                </ListItem>
                            ))
                        ) : (
                            <NftListSkeleton rows={5} />
                        )}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary" size="large" variant="outlined">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default styled(CurrencySelect)`
    width: 100%;

    .btn {
        width: 100%;
        justify-content: space-between;
        border-radius: ${({ theme }) => theme.shape.borderRadius}px;
        padding: ${({ theme }) => theme.spacing(2)};
        border: 1px solid ${({ theme }) => theme.palette.action.disabled};
    }
`;
