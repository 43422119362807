import React, { useState } from 'react';
import styled from 'styled-components';
import { WalletListItem, WalletListItemSkeleton } from '../../components';
import { useSelector } from 'react-redux';
import { useBalance } from '../../../../api';

interface MobileHistoryWithdrawProps {
    className?: string;
}

const MobileWallet = ({ className }: MobileHistoryWithdrawProps) => {
    const addresses = useSelector((state: Redux.RootState) => state.app.addresses);
    const [activeWallet, setActiveWallet] = useState('AOK');
    const { data: balances, isLoading } = useBalance({ addresses: addresses! });

    return (
        <div className={className}>
            {!isLoading && balances ? (
                <WalletListItem
                    {...balances.balance}
                    currency="AOK"
                    active={'AOK' === activeWallet}
                    className="wallet-item-mobile"
                    onClick={() => {
                        setActiveWallet('AOK');
                    }}
                />
            ) : (
                <WalletListItemSkeleton className="wallet-item-mobile" />
            )}
        </div>
    );
};

export default styled(MobileWallet)`
    margin: ${({ theme }) => theme.spacing(2, 4)};

    .wallet-item-mobile {
        width: 100%;
    }

    .paper-no-token {
        opacity: 0.9;
        width: 100%;
        height: 160px;
        padding: ${({ theme }) => theme.spacing(2)};
    }
`;
