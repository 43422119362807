import React from 'react';
import { ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';
import styled from 'styled-components';

interface Props {
    className?: string;
    rows?: number;
}

const HistorySkeleton = ({ className, rows }: Props) => {
    return (
        <>
            {Array(rows)
                .fill(null)
                .map((tx, index) => (
                    <ListItem key={index} className={className}>
                        <ListItemAvatar>
                            <Skeleton variant="circular" width={40} height={40} />
                        </ListItemAvatar>
                        <ListItemText>
                            <div className="display-flex">
                                <Skeleton variant="text" width={70} />
                                <Skeleton variant="text" width={80} />
                            </div>
                            <div className="display-flex align-end">
                                <Skeleton variant="text" width={100} />
                                <Skeleton variant="text" width={80} />
                            </div>
                        </ListItemText>
                    </ListItem>
                ))}
        </>
    );
};

export default styled(HistorySkeleton)`
    display: block;
    .display-flex {
        display: flex;
        justify-content: space-between;
    }
    .align-end {
        align-items: flex-end;
    }
`;
