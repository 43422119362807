import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    Hidden,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { IoMdKey } from 'react-icons/io';
import NumberFormat from 'react-number-format';
import { IoWalletOutline } from 'react-icons/io5';
import 'date-fns';
import { createRawTransation } from '../../../../services/common';
import { useSelector } from 'react-redux';
import { isAddress as isAOKAddress } from '../../../../services/address';
import { broadcast, useBalance, useTransactions } from '../../../../api';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import aok from '../../../../assets/aok/aok-logo-xs.svg';
import { CurrencySelect } from '../../components';

interface WithdrawProps {
    className?: string;
}

const Withdraw = ({ className }: WithdrawProps) => {
    const {
        register,
        handleSubmit,
        control,
        setValue,
        reset,
        watch,
        formState: { errors: fieldsErrors },
    } = useForm<{ address: string; amount: string; fee: string }>({ defaultValues: { fee: String(0.001) } });

    const addresses = useSelector((state: Redux.RootState) => state.app.addresses);
    const depositAddress = useSelector((state: Redux.RootState) => state.app.depositAddress);
    const currency = useSelector((state: Redux.RootState) => state.app.currency);

    const { data: balances, refetch: refetchBalances } = useBalance({ addresses: addresses! });
    const { refetch: refetchTransactions } = useTransactions({ addresses: addresses! });

    const [isFeeActive, setIsFeeActive] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const watchFee = watch('fee', '0.001');
    const watchAmount = watch('amount', '0');

    const isAddress = (address: string) => isAOKAddress(address) || 'AOK address is not correct';

    const onSubmit = async (data: { address: string; amount: string; fee: string }) => {
        try {
            setIsSending(true);

            if (addresses && depositAddress) {
                const raw = await createRawTransation(
                    addresses,
                    depositAddress.address,
                    data.address,
                    10 ** 8 * parseFloat(data.amount),
                    10 ** 8 * parseFloat(data.fee),
                    undefined,
                    currency,
                );

                if (raw) {
                    const tx = await broadcast({ raw });

                    if (tx) {
                        refetchTransactions();
                        refetchBalances();
                        reset();
                        enqueueSnackbar('Transaction has been successfully sent!', {
                            variant: 'success',
                        });
                    }
                }
            }

            setIsSending(false);
        } catch (e) {
            if (e instanceof Error)
                enqueueSnackbar(e.message, {
                    variant: 'error',
                });

            setIsSending(false);
        }
    };
    const unlockFee = () => {
        setIsFeeActive((prev) => !prev);
    };

    const setMaxBalance = () => {
        if (balances) {
            if (currency === 'AOK') {
                if (balances.balance.confirmed > 0) {
                    const value = balances.balance.confirmed / 10 ** 8 - parseFloat(watchFee);
                    setValue('amount', String(value));
                }
            } else {
                const b = balances.tokens.filter((t) => t.tokenName === currency)[0].confirmed;
                if (b > 0) {
                    setValue('amount', String(b / 10 ** 8));
                }
            }
        }
    };

    useEffect(() => {
        setValue('amount', '');
    }, [currency]);

    const form = (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth variant="outlined" className="form">
                <CurrencySelect />
                <Controller
                    name="address"
                    render={({ field }) => (
                        <TextField
                            {...field}
                            id="address"
                            className="txt-field"
                            variant="outlined"
                            color="primary"
                            label="Withdraw Address"
                            placeholder="KXStk8wJupWJ4vsDu1ZxkmcM5U6L2yrWoD"
                            helperText={fieldsErrors.address ? fieldsErrors.address.message : undefined}
                            error={Boolean(fieldsErrors.address)}
                            InputLabelProps={{ shrink: true }}
                            {...register('address')}
                        />
                    )}
                    control={control}
                    rules={{
                        required: 'Address required',
                        validate: isAddress,
                    }}
                />
                <Controller
                    name="amount"
                    render={({ field }) => (
                        <NumberFormat
                            {...field}
                            customInput={TextField}
                            thousandSeparator
                            allowNegative={false}
                            className="txt-field"
                            label="Amount"
                            placeholder="Enter the amount"
                            isNumericString
                            helperText={fieldsErrors.amount ? fieldsErrors.amount.message : undefined}
                            error={Boolean(fieldsErrors.amount)}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <InputAdornment position="end">{currency}</InputAdornment>
                                        <InputAdornment position="end">
                                            <Button onClick={setMaxBalance} sx={{ minWidth: 0 }} size="small">
                                                Max
                                            </Button>
                                        </InputAdornment>
                                    </>
                                ),
                            }}
                        />
                    )}
                    control={control}
                    rules={{
                        required: 'Amount required',
                        min: {
                            value: 0,
                            message: 'Amount must be a positive number',
                        },
                    }}
                />
                <Controller
                    name="fee"
                    render={({ field }) => (
                        <NumberFormat
                            {...field}
                            customInput={TextField}
                            thousandSeparator
                            allowNegative={false}
                            className="txt-field"
                            label="Fee"
                            placeholder="Enter the fee"
                            isNumericString
                            disabled={!isFeeActive}
                            defaultValue={0.001}
                            helperText={fieldsErrors.fee ? fieldsErrors.fee.message : undefined}
                            error={Boolean(fieldsErrors.fee)}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        <InputAdornment position="end">{currency}</InputAdornment>
                                        <InputAdornment position="end">
                                            <IconButton onClick={unlockFee} edge="end" size="small">
                                                {isFeeActive ? <IoMdKey /> : <IoMdKey className="icon-enabled" />}
                                            </IconButton>
                                        </InputAdornment>
                                    </>
                                ),
                            }}
                        />
                    )}
                    control={control}
                    rules={{
                        required: 'Fee required',
                        min: {
                            value: 0.001,
                            message: 'Min value of fee is 0.001',
                        },
                    }}
                />
            </FormControl>
            <Divider className="divider" />
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} md={8} sm={8}>
                    <Typography variant="body2">Total send:</Typography>
                    <div className="total-box">
                        <Typography
                            variant="h5"
                            color="primary"
                            className="total-send-value"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            noWrap
                        >
                            <NumberFormat
                                displayType="text"
                                value={
                                    !currency || currency === 'AOK'
                                        ? (parseFloat(watchFee) || 0) + (parseFloat(watchAmount) || 0)
                                        : watchAmount || 0
                                }
                                isNumericString
                                decimalScale={4}
                                thousandSeparator
                                fixedDecimalScale
                            />
                        </Typography>
                        {currency === 'AOK' ? (
                            <div>
                                <img src={aok} alt="coin" className="coin-logo" />
                            </div>
                        ) : (
                            <Typography variant="h6" color="primary" className="total-send-currency" fontWeight="bold">
                                {currency}
                            </Typography>
                        )}
                    </div>
                </Grid>
                <Grid item xs="auto" md="auto" sm="auto" justifyContent="flex-end">
                    <LoadingButton
                        loading={isSending}
                        loadingPosition="start"
                        color="primary"
                        variant="contained"
                        type="submit"
                        startIcon={<IoWalletOutline />}
                        size="large"
                    >
                        <Typography color="white" variant="button">
                            Send
                        </Typography>
                    </LoadingButton>
                </Grid>
            </Grid>
        </form>
    );

    return (
        <Box className={className}>
            <Hidden lgDown>
                <Typography color="primary" variant="h5">
                    Withdraw
                </Typography>
            </Hidden>
            {form}
        </Box>
    );
};

export default styled(Withdraw)`
    padding: ${({ theme }) => theme.spacing(4)};

    .txt-field {
        margin-top: ${({ theme }) => theme.spacing(2)};
    }

    .icon-enabled {
        color: ${({ theme }) => theme.palette.grey[900]};
    }

    .form,
    .divider {
        margin-bottom: ${({ theme }) => theme.spacing(3)};
    }

    .total-box {
        display: flex;
        align-items: flex-end;

        .total-send-value {
            font-family: 'Titillium Web', sans-serif;
            padding-right: ${({ theme }) => theme.spacing(0)};
        }

        .total-send-currency {
            line-height: 1.8;
            padding-bottom: 1px;
        }

        .coin-logo {
            padding-bottom: 3px;
        }
    }
`;
