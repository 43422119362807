import { useQuery } from 'react-query';
import axios from 'axios';
import { createTransactionFromAPI } from '../services/common';

type Params = {
    addresses: AOK.Address[];
    after?: string | undefined;
    before?: string | undefined;
    count?: number | undefined;
};

async function fetch(params: Params) {
    try {
        const walletAddresses = [...new Set(params.addresses.map((address: any) => address.address))];

        const {
            data: { result },
        } = await axios.post(`${axios.defaults.baseURL}/wallet/history/`, {
            ...params,
            addresses: walletAddresses,
        });

        const promises = [];
        const transactions: AOK.Transaction[] = [];

        for (let i = 0; i < result.length; i++) {
            promises.push(
                createTransactionFromAPI(result[i], walletAddresses).then((transaction: any) => {
                    transactions.push(transaction);
                }),
            );
        }

        await Promise.all(promises);

        return transactions;
    } catch (e) {
        console.error(e);
        throw e;
    }
}

export default function (params: Params, options?: Record<string, any>) {
    return useQuery<AOK.Transaction[], Error>(['transactions', params], () => fetch(params), options);
}
