import React, { useState } from 'react';
import styled from 'styled-components';
import {
    Box,
    Container,
    Divider,
    Drawer,
    IconButton,
    Link,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Toolbar,
    Typography,
    useTheme,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { IoExitOutline, IoInformation, IoList, IoMenuOutline } from 'react-icons/io5';
import logo from '../../../assets/aok/logo.svg';

interface MobileNavigationProps {
    className?: string;
    logDialog: (open: boolean) => void;
}

const MobileNavigation = ({ className, logDialog }: MobileNavigationProps) => {
    const theme = useTheme();
    const [drawer, setDrawer] = useState(false);

    const logout = () => {
        logDialog(true);
    };

    const toggleDrawer = (open: boolean) => (event: any) => {
        if (
            event.type === 'keydown' &&
            ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setDrawer(open);
    };

    return (
        <div className={className}>
            <Container>
                <Toolbar>
                    <Link component={NavLink} to="/home" className="logo">
                        <Box display="flex" justifyItems="center">
                            <img src={logo} alt="Logo" />
                            <Typography className="title" variant="h6" color="white" display="inline">
                                AOK Wallet
                            </Typography>
                        </Box>
                    </Link>
                    <IconButton size="large">
                        <IoMenuOutline onClick={() => setDrawer(true)} color="white" />
                    </IconButton>
                    <Drawer anchor="right" open={drawer} onClose={toggleDrawer(false)}>
                        <MenuList
                            style={{ width: '260px' }}
                            role="list"
                            onClick={toggleDrawer(false)}
                            onKeyDown={toggleDrawer(false)}
                        >
                            <Link href="https://aok.network/" target="_blank" color="primary" underline="none">
                                <MenuItem>
                                    <ListItemIcon style={{ color: theme.palette.primary.main }}>
                                        <IoInformation />
                                    </ListItemIcon>
                                    <ListItemText primary="About Us" />
                                </MenuItem>
                            </Link>
                            <Link href="https://aokscan.com/" target="_blank" color="primary" underline="none">
                                <MenuItem>
                                    <ListItemIcon style={{ color: theme.palette.primary.main }}>
                                        <IoList />
                                    </ListItemIcon>
                                    <ListItemText primary="AOK Explorer" />
                                </MenuItem>
                            </Link>
                            <Divider />
                            <MenuItem onClick={logout}>
                                <ListItemIcon style={{ color: theme.palette.primary.main }}>
                                    <IoExitOutline />
                                </ListItemIcon>
                                <ListItemText primary="Log out" />
                            </MenuItem>
                        </MenuList>
                    </Drawer>
                </Toolbar>
            </Container>
        </div>
    );
};

export default styled(MobileNavigation)`
    .logo {
        flex-grow: 1;
    }

    .title {
        padding-left: ${({ theme }) => theme.spacing(1)};
    }
`;
