import React from 'react';
import { Avatar, ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';
import styled from 'styled-components';

interface Props {
    className?: string;
    rows?: number;
}

const NftListSkeleton = ({ className, rows }: Props) => {
    return (
        <>
            {Array(rows)
                .fill(null)
                .map((tx, index) => (
                    <ListItem key={index} className={className}>
                        <ListItemAvatar>
                            <Skeleton variant="circular">
                                <Avatar />
                            </Skeleton>
                        </ListItemAvatar>
                        <ListItemText primary={<Skeleton width={200} />} secondary={<Skeleton width={100} />} />
                    </ListItem>
                ))}
        </>
    );
};

export default styled(NftListSkeleton)``;
