import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import rootReducer from '../reducers/index';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['app'],
    blacklist: [],
};

const persistedReducer = persistReducer<Redux.RootState>(persistConfig, rootReducer);

const enhancer = compose(applyMiddleware(thunk), applyMiddleware(createLogger()));

const store = createStore(persistedReducer, composeWithDevTools(enhancer));

const persistor = persistStore(store);

export { store, persistor };
