import React from 'react';
import { Button, Paper, Typography } from '@mui/material';
import styled from 'styled-components';
import 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { BiDownload } from 'react-icons/all';
import { HistoryList } from '../../components';
import { setState } from '../../../../redux/actions/appActions';

interface HistoryProps {
    className?: string;
}

const History = ({ className }: HistoryProps) => {
    const expanded = useSelector((state: Redux.RootState) => state.app.expandedHistory);
    const dispatch = useDispatch();

    return (
        <Paper className={className}>
            <Typography color="primary" variant="h5">
                History
            </Typography>
            <HistoryList count={!expanded ? 5 : undefined} />
            <Button
                color="primary"
                endIcon={<BiDownload />}
                onClick={() => dispatch(setState({ expandedHistory: !expanded }))}
            >
                {expanded ? 'Less Transactions' : 'More Transactions'}
            </Button>
        </Paper>
    );
};

export default styled(History)`
    padding: ${({ theme }) => theme.spacing(4)};
    height: fit-content;
    position: relative;

    .expanded-list {
        max-height: 50vh;
        overflow: auto;
        margin-top: ${({ theme }) => theme.spacing(4)};
    }

    .footer-box {
        position: absolute;
        bottom: ${({ theme }) => theme.spacing(4)};
        width: 100%;
        padding-right: ${({ theme }) => theme.spacing(5)};
    }

    .search-field {
        margin-top: ${({ theme }) => theme.spacing(2)};
    }
`;
