import React from 'react';
import { Box, Button, Container, Link, Toolbar, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { IoExitOutline } from 'react-icons/io5';
import logo from '../../../assets/aok/logo.svg';

interface NavigationProps {
    className?: string;
    logDialog: (open: boolean) => void;
}

const DesktopNavigation = ({ className, logDialog }: NavigationProps) => {
    return (
        <div className={className}>
            <Container>
                <Toolbar>
                    <Link component={NavLink} to="/home" className="logo">
                        <Box display="flex" justifyItems="center">
                            <img src={logo} alt="Logo" />
                            <Typography className="title" variant="h6" color="white" display="inline">
                                AOK Wallet
                            </Typography>
                        </Box>
                    </Link>
                    <Link
                        href="https://aokscan.com/"
                        target="_blank"
                        className="nav-item"
                        variant="body1"
                        color="inherit"
                        underline="none"
                    >
                        AOK Explorer
                    </Link>
                    <Link
                        href="https://aok.network"
                        color="inherit"
                        target="_blank"
                        className="nav-item"
                        variant="body1"
                        underline="none"
                    >
                        About us
                    </Link>
                    <Button
                        variant="outlined"
                        color="inherit"
                        startIcon={<IoExitOutline />}
                        onClick={() => logDialog(true)}
                    >
                        Log out
                    </Button>
                </Toolbar>
            </Container>
        </div>
    );
};

export default styled(DesktopNavigation)`
    flex-grow: 1;
    color: ${({ theme }) => theme.palette.common.white};

    .nav-btn {
        border-color: ${({ theme }) => theme.palette.common.white};
    }

    .nav-item {
        margin: 0 ${({ theme }) => theme.spacing(2)};
    }

    .logo {
        flex-grow: 1;
    }

    .title {
        padding-left: ${({ theme }) => theme.spacing(1)};
    }
`;
